import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import HeroImage from "../../components/global/heroImage";
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import parse from "html-react-parser";
import "./contact-us.scss"

const ContactPage = () => {

    const data = useStaticQuery(graphql`
    {
      craft {
        page: entry(section: "contactUs") {
          ... on Craft_contactUs_contactUs_Entry {
            id
            title
            pageTitle
            uri
            contactDetails {
                ... on Craft_contactDetails_contactDetails_BlockType {
                    details
              }
            }
            aboutUsHeroImage {
              ... on Craft_aboutUsHeroImage_image_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websiteGeneral_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
          }
        }
      }
    }
  `)

    const getContactPosts = (contactDetails) => {
        if (contactDetails.length > 0) {
            return (
                contactDetails.map(contact => (
                    <div className="contact-post">
                        {parse(contact.details)}
                    </div>
                ))
            )
        } else {
            return null
        }
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd">


                <PageTitle title={data.craft.page.pageTitle} />

                <div className="content-page">
                    <div className="container-small">

                        <HeroImage image={data.craft.page.aboutUsHeroImage}/>

                        <div className="contact-page">
                            {getContactPosts(data.craft.page.contactDetails)}
                        </div>

                    </div>
                </div>

            </div>
        </InnerLayout>
    )
}

export default ContactPage
